<template>
    <div class="ScoreBoardList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">

                <div class="btn2">
                    <span style="height: 40px; line-height: 40px; margin-left: 10px">榜单年度：</span>
                    <el-select v-model="scoreBoardYearId" placeholder="榜单年度" filterable style="width: 150px">
                        <el-option
                                v-for="item in scoreBoardYearList"
                                :key="item.id"
                                :label="item.scoreBoardYear"
                                :value="item.id"
                        ></el-option>
                    </el-select>

                    <el-button style="height: 40px; margin-left: 10px" type="primary" plain @click="loadBoardList">查询</el-button>
                    <el-button style="height: 40px; margin-left: 10px" type="primary" plain @click="handleBoardAdd">添加</el-button>
                </div>
            </div>

            <div class="main-card">
                <el-table :data="scoreBoardList" style="width: 100%" height="540"
                          :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
                    <el-table-column label="榜单年属">
                        <template slot-scope="scope">
                            <span>{{ scope.row.scoreBoardYear }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="榜单">
                        <template slot-scope="scope">
                            <span>{{ scope.row.boardName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="显示顺序">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sequence }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="当前默认显示">
                        <template slot-scope="scope">
                            <span v-if="1 === scope.row.isCurrent">是</span>
                            <span v-if="1 !== scope.row.isCurrent">否</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="星号显示类型">
                        <template slot-scope="scope">
                            <span v-if="1 === scope.row.starShowType">前100 2颗星, 前200 1颗星</span>
                            <span v-if="1 !== scope.row.starShowType">不显示</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="显示代表队名">
                        <template slot-scope="scope">
                            <span v-if="1 === scope.row.showTeam">是</span>
                            <span v-if="1 !== scope.row.showTeam">否</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="handleBoardEdit(scope.$index, scope.row)">编辑
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleBoardDelete(scope.row.id)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="page-card">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                >
                </el-pagination>
            </div>
        </el-card>

        <el-dialog
                title="榜单"
                :visible.sync="dialogeditBoardFormVisible"
                width="60%"
                class="group"
                :close-on-click-modal="false">
            <el-form
                    ref="editBoardForm"
                    :model="editBoardForm"
                    label-width="200px"
                    :label-position="labelPosition"
                    :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="榜单年属:" prop="scoreBoardYear">
                            <el-select v-model="editBoardForm.scoreBoardYearId" placeholder="榜单年度" filterable style="width: 217px"
                                       @change="handleEditFormBoardYearListChange($event)">
                                <el-option
                                        v-for="item in scoreBoardYearList"
                                        :key="item.id"
                                        :label="item.scoreBoardYear"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="榜单:" prop="boardName">
                            <el-input v-model="editBoardForm.boardName" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="显示顺序:" prop="sequence">
                            <el-input v-model="editBoardForm.sequence" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="星号显示类型:" prop="starShowType">
                            <el-select v-model="editBoardForm.starShowType">
                                <el-option label="不显示" :value="0"></el-option>
                                <el-option label="前100 2颗星, 前200 1颗星" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="显示代表队名:" prop="showTeam">
                            <el-switch
                                    class="switch"
                                    style="display: block; width: 200px"
                                    v-model="editBoardForm.showTeam"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="数据来源榜单:" prop="fromBoard">
                            <el-select v-model="editFormFromBoardIds" placeholder="数据来源榜单" multiple filterable style="width: 217px">
                                <el-option
                                        v-for="item in editFormScoreBoardList"
                                        :key="item.id"
                                        :label="item.scoreBoardYear + ' - '+ item.boardName"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveBoard">提交</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import store from "../store";

    export default {
        data() {
            return {
                memu:this.$route.params.menu,

                stores: store,

                currentPage: 1,
                pageSize: 20,
                totalCount: 0,
                dialogeditBoardFormVisible: false,
                labelPosition: "right",
                editBoardForm: {},
                editFormScoreBoardList: [],
                editFormFromBoardIds: [],

                scoreBoardYearId: null,

                scoreBoardYearList: [],
                scoreBoardList: [],

                rules: {
                    scoreBoardYearId: [
                        { required: true, message: "请选择榜单年属", trigger: "blur" },
                    ],
                    boardName: [
                        { required: true, message: "请输入榜单名称", trigger: "blur" },
                    ],
                    sequence: [
                        { required: true, message: "请输入显示顺序", trigger: "blur" },
                        { pattern: /^[1-9][0-9]*$/, message: "请输入正确的显示顺序", trigger: "blur" }
                    ],
                },
            };
        },
        created() {
            this.loadBoardYearList();
        },
        methods: {

            async loadBoardYearList() {
                try {
                    const result = await this.$api.loadBoardYearList(
                        `currentPage=1&pageSize=50`
                    );

                    this.scoreBoardYearList = result.rows;
                } catch (error) {
                    console.log(error);
                }
            },
            //榜单列表
            async loadBoardList() {
                if (this.scoreBoardYearId == null) {
                    this.$message({
                        type: "error",
                        message: "请选择榜单年度",
                    });
                    return;
                }

                try {
                    let paramString = `currentPage=${this.currentPage}&pageSize=${this.pageSize}`;
                    paramString += `&search_EQ_scoreBoardYearId=${this.scoreBoardYearId}`;
                    const result = await this.$api.loadBoardList(
                        paramString
                    );

                    this.scoreBoardList = result.rows;
                    this.totalCount = result.total;

                } catch (error) {
                    console.log(error);
                }
            },

            async handleEditFormBoardYearListChange(scoreBoardYearId) {

                if (scoreBoardYearId == null) {
                    this.$message({
                        type: "error",
                        message: "请选择榜单年属",
                    });
                    return;
                }
                this.editFormFromBoardIds = [];
                try {
                    const result = await this.$api.loadBoardList(
                        `currentPage=1&pageSize=50&search_EQ_scoreBoardYearId=` + scoreBoardYearId
                    );

                    this.editFormScoreBoardList = result.rows;
                } catch (error) {
                    console.log(error);
                }
            },

            handleBoardDelete(id) {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(async () => {
                    try {
                        const result = await this.$api.deleteScoreBoard(`scoreBoardId=${id}`);
                        if (result.data.status_code == "200") {
                            this.$message({
                                type: "success",
                                message: "删除成功",
                            });
                            await this.loadBoardList();
                        } else if (result.data.status_code == "1002") {
                            this.$message({
                                type: "warning",
                                message: result.data.msg,
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            },
            async handleBoardEdit(index, row) {
                console.log(row);

                this.editBoardForm = JSON.parse(JSON.stringify(row));
                delete this.editBoardForm.boardPlayers;
                this.editFormFromBoardIds = [];
                await this.handleEditFormBoardYearListChange(this.editBoardForm.scoreBoardYearId);

                if (this.editBoardForm.fromBoard) {

                    let fromBoardIds = this.editBoardForm.fromBoard.split(",");
                    fromBoardIds.forEach(value => {
                        this.editFormFromBoardIds.push(parseInt(value));
                    });
                }
                this.dialogeditBoardFormVisible = true;
                console.log(this.editBoardForm);
            },
            handleBoardAdd() {
                this.editBoardForm = {starShowType: 0, showTeam: 0, isCurrent:0, fromBoard: null}
                this.editFormFromBoardIds = [];

                this.dialogeditBoardFormVisible = true;
            },
            async saveBoard() {
                try {
                    this.editBoardForm.fromBoard = null;

                    if (this.editFormFromBoardIds != null && this.editFormFromBoardIds.length > 0) {
                        this.editBoardForm.fromBoard = this.editFormFromBoardIds.join(",");
                    }

                    console.log(this.editBoardForm);
                    const result = await this.$api.saveScoreBoard(this.editBoardForm);
                    console.log(result);
                    if (result.data.status_code == "200") {
                        this.$message({
                            type: "success",
                            message: "保存成功",
                        });
                        await this.loadBoardList();
                    } else if (result.data.status_code == "1002") {
                        this.$message({
                            type: "warning",
                            message: result.data.msg,
                        });
                    }
                } catch (error) {
                    console.log(error);
                }

                this.dialogeditBoardFormVisible = false;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.loadBoardList();
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.loadBoardList();
                console.log(`当前页: ${val}`);
            },
        },
    };
</script>

<style lang="less">
    .ScoreBoardList {
        .box-card {
            width: 100%;

            .clearfix {
                &:before,
                &:after {
                    display: table;
                    content: "";
                }

                &:after {
                    clear: both;
                }

                .btn1 {
                    display: flex;
                    // float: right;
                    .select1 {
                        width: 150px;
                        margin-right: 20px;
                    }

                    .whether {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .btn2 {
                    display: flex;
                }
            }

            .select2 {
                width: 100px;
                margin-right: 20px;
            }

            .btn2 {
                display: flex;
                margin: 20px 0;
            }

            .main-card {
                .payInfo {
                    margin: 10px 0;

                    .pay {
                        font-size: 20px;
                    }
                }
            }

            .page-card {
                margin: 30px 0;
                float: right;
            }

            .text {
                font-size: 14px;
            }

            .item {
                margin-bottom: 18px;
            }
        }

        .dialog-footer,
        .el-dialog__header {
            text-align: center;
        }

        .el-col-11 {
            width: 20%;
        }

        .el-col-2 {
            width: 1.3%;
        }
    }
</style>
