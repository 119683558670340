<template>
    <div class="ScoreBoard">
        <el-page-header @back="goBack" :content="back" title=""></el-page-header>
        <el-tabs v-model="activeName">
            <el-tab-pane label="榜单" name="1">
                <Bar1></Bar1>
            </el-tab-pane>
            <el-tab-pane label="设置" name="2">
                <Bar2></Bar2>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Bar1 from '../../components/ScoreBoardList.vue'
    import Bar2 from '../../components/ScoreBoardYear.vue'

    export default {
        data() {
            return {
                back: this.$route.params.back ? this.$route.params.back:'榜单管理',
                memu: this.$route.params.menu,

                activeName: '1',
                tab: {},
            }
        },
        components: {
            Bar1,
            Bar2
        },
        mounted() {
        },
        methods: {
            goBack() {
                this.$router.push({name: 'Home'})
            },
        },
    };
</script>

<style lang="less" scoped>
    .ScoreBoard {
    }
</style>
