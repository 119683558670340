<template>
    <div class="ScoreBoardList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-button style="height: 40px; margin-left: 10px" type="primary" plain @click="handleBoardYearAdd">添加
                </el-button>
            </div>

            <div class="main-card">
                <el-table :data="scoreBoardYearList" style="width: 100%" height="540"
                          :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
                    <el-table-column label="榜单年属">
                        <template slot-scope="scope">
                            <span>{{ scope.row.scoreBoardYear }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="海报年属图标">
                        <template slot-scope="scope">
                            <img v-if="scope.row.yearTagImg" :src="scope.row.yearTagImg"/>
                        </template>
                    </el-table-column>

                    <el-table-column label="榜单选项图标">
                        <template slot-scope="scope">
                            <img v-if="scope.row.selectTagImg" :src="scope.row.selectTagImg"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="默认显示年度">
                        <template slot-scope="scope">
                            <el-switch
                                    disabled
                                    class="switch"
                                    style=""
                                    v-model="scope.row.isCurrent"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否发布">
                        <template slot-scope="scope">
                            <el-switch
                                    disabled
                                    class="switch"
                                    style=""
                                    v-model="scope.row.isPublished"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="handleBoardYearEdit(scope.$index, scope.row)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="page-card">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                >
                </el-pagination>
            </div>
        </el-card>

        <el-dialog
                title="榜单年度"
                :visible.sync="dialogEditBoardYearFormVisible"
                width="60%"
                class="group"
                :close-on-click-modal="false">
            <el-form
                    ref="editBoardYearForm"
                    :model="editBoardYearForm"
                    label-width="200px"
                    :label-position="labelPosition"
                    :rules="rules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="榜单年属:" prop="scoreBoardYear">
                            <el-input v-model="editBoardYearForm.scoreBoardYear" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="默认显示年度:" prop="isCurrent">
                            <el-switch
                                    class="switch"
                                    style=""
                                    v-model="editBoardYearForm.isCurrent"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否已发布:" prop="isCurrent">
                            <el-switch
                                    class="switch"
                                    style=""
                                    v-model="editBoardYearForm.isPublished"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-text="是"
                                    inactive-text="否">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="海报年属图标:" prop="yearTagImg">
                            <el-upload
                                    action="javascript:void(0)"
                                    class="img-uploader"
                                    :show-file-list="false"
                                    accept="image/png"
                                    :before-upload="handleBeforeUploadImage"
                                    :http-request="uploadBoardYearTagImg">
                                <img v-if="editBoardYearForm.yearTagImg" :src="editBoardYearForm.yearTagImg"
                                     class="year-tag-img">
                                <i v-else class="el-icon-plus img-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="榜单选项图标:" prop="selectTagImg">
                            <el-upload
                                    action="javascript:void(0)"
                                    class="img-uploader"
                                    :show-file-list="false"
                                    accept="image/png"
                                    :before-upload="handleBeforeUploadImage"
                                    :http-request="uploadBoardSelectTagImg">
                                <img v-if="editBoardYearForm.selectTagImg" :src="editBoardYearForm.selectTagImg"
                                     class="select-tag-img">
                                <i v-else class="el-icon-plus img-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveBoardYear">提交</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import store from "../store";

    export default {
        data() {
            return {
                memu: this.$route.params.menu,

                stores: store,

                currentPage: 1,
                pageSize: 10,
                totalCount: 0,
                dialogEditBoardYearFormVisible: false,
                labelPosition: "right",
                editBoardYearForm: {},
                scoreBoardYearList: [],
                uploadTempImageFile: null,

                rules: {
                    scoreBoardYear: [
                        {required: true, message: "请选择榜单年属", trigger: "blur"},
                        {pattern: /^[1-9][0-9]*$/, message: "请输入正确的榜单年属", trigger: "blur"}
                    ],
                    yearTagImg: [
                        {required: true, message: "海报年属图标", trigger: "blur"},
                    ],
                    selectTagImg: [
                        {required: true, message: "榜单选项图标", trigger: "blur"},
                    ],
                },
            };
        },
        created() {
            this.loadBoardYearList();
        },
        methods: {

            async loadBoardYearList() {
                try {
                    let paramString = `currentPage=${this.currentPage}&pageSize=${this.pageSize}`;
                    const result = await this.$api.loadBoardYearList(
                        paramString
                    );

                    this.scoreBoardYearList = result.rows;
                    this.totalCount = result.total;
                } catch (error) {
                    console.log(error);
                }
            },
            uploadBoardYearTagImg() {
                this.proceedBoardYearImgUpload('year_tag_img');
            },

            uploadBoardSelectTagImg() {
                this.proceedBoardYearImgUpload('select_tag_img');
            },

            proceedBoardYearImgUpload(imageType) {
                if (!this.uploadTempImageFile) {
                    return;
                }

                try {
                    this.$api.imageUploadConfig(
                        `fileName=${this.uploadTempImageFile.name}`
                    ).then((uploadconfig) => {
                        console.log(uploadconfig);
                        let formData = new FormData();
                        formData.append("authorization", uploadconfig.authorization);
                        formData.append("policy", uploadconfig.policy);
                        formData.append("file", this.uploadTempImageFile);

                        this.$http
                            .post(uploadconfig.commitPath, formData, {
                                timeout: 3600000,
                                onUploadProgress: (e) => {
                                    if (e.lengthComputable) {
                                        let rate = ((e.loaded * 100) / e.total).toFixed(2);
                                        if (rate < 100) {
                                        }
                                    }
                                },
                            })
                            .then((res) => {
                                if ('year_tag_img' === imageType) {
                                    this.editBoardYearForm.yearTagImg = uploadconfig.showDomain + res.data.url.substring(1);
                                } else if ('select_tag_img' === imageType) {
                                    this.editBoardYearForm.selectTagImg = uploadconfig.showDomain + res.data.url.substring(1);
                                }
                            });
                    });
                } catch (error) {
                    console.log(error);
                }
                return true;
            },

            handleBeforeUploadImage(file) {
                this.uploadTempImageFile = null;
                const isPNG = file.type === 'image/png';
                if (!isPNG) {
                    this.$message({
                        type: "error",
                        message: '请使用PNG格式图片',
                    });
                    return false;
                }

                const isLessThan2M = file.size / 1024 / 1024 < 2;
                if (!isLessThan2M) {
                    this.$message({
                        type: "error",
                        message: '图片不能大于2M',
                    });
                    return false;
                }
                this.uploadTempImageFile = file;
                return true;
            },

            handleBoardYearEdit(index, row) {
                console.log(row);
                this.editBoardYearForm = JSON.parse(JSON.stringify(row));
                this.dialogEditBoardYearFormVisible = true;
            },
            handleBoardYearAdd() {
                this.editBoardYearForm = {scoreBoardYear: null, isPublished:0, isCurrent: 0, yearTagImg: null, selectTagImg: null}
                this.dialogEditBoardYearFormVisible = true;
            },

            async saveBoardYear() {
                try {
                    if (!this.editBoardYearForm.yearTagImg) {
                        this.$message({
                            type: "error",
                            message: "请上传海报年属图标",
                        });
                        return false;
                    }

                    if (!this.editBoardYearForm.selectTagImg) {
                        this.$message({
                            type: "error",
                            message: "请上传榜单选项图标",
                        });
                        return false;
                    }

                    const result = await this.$api.saveScoreBoardYear(this.editBoardYearForm);
                    console.log(result);
                    if (result.data.status_code == "200") {
                        this.$message({
                            type: "success",
                            message: "保存成功",
                        });
                        await this.loadBoardYearList();
                    } else if (result.data.status_code == "1002") {
                        this.$message({
                            type: "warning",
                            message: result.data.msg,
                        });
                    }
                } catch (error) {
                    console.log(error);
                }

                this.dialogEditBoardYearFormVisible = false;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.loadBoardYearList();
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.loadBoardYearList();
                console.log(`当前页: ${val}`);
            },
        },
    };
</script>

<style lang="less">
    .ScoreBoardList {
        .box-card {
            width: 100%;

            .clearfix {
                &:before,
                &:after {
                    display: table;
                    content: "";
                }

                &:after {
                    clear: both;
                }

                .btn1 {
                    display: flex;
                    // float: right;
                    .select1 {
                        width: 150px;
                        margin-right: 20px;
                    }

                    .whether {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .btn2 {
                    display: flex;
                }
            }

            .select2 {
                width: 100px;
                margin-right: 20px;
            }

            .btn2 {
                display: flex;
                margin: 20px 0;
            }

            .main-card {
                .payInfo {
                    margin: 10px 0;

                    .pay {
                        font-size: 20px;
                    }
                }
                img {
                    max-width: 95%;
                }
            }

            .page-card {
                margin: 30px 0;
                float: right;
            }

            .text {
                font-size: 14px;
            }

            .item {
                margin-bottom: 18px;
            }
        }

        .dialog-footer,
        .el-dialog__header {
            text-align: center;
        }

        .el-col-11 {
            width: 20%;
        }

        .el-col-2 {
            width: 1.3%;
        }

        .img-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .img-uploader .el-upload:hover {
            border-color: #409EFF;
        }

        .img-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 398px;
            height: 65px;
            line-height: 65px;
            text-align: center;
        }

        .year-tag-img {
            width: 398px;
            height: 65px;
            display: block;
        }

        .select-tag-img {
            width: 398px;
            height: 65px;
            display: block;
        }
    }
</style>
